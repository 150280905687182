import {ICookieMessageConfig} from "../Interfaces/Configuration/ICookieMessageConfig";
import {ICookieMessageService} from "../Interfaces/ICookieMessageService";

export class CookieMessageService implements ICookieMessageService {
    private readonly Configuration: ICookieMessageConfig;

    constructor(config: ICookieMessageConfig) {
        this.Configuration = config;
    }

    public GenerateCookieMessage(isMobile): string {
        // Pass in configuration in order to construct the content.
        let config = this.Configuration;
        let mobileClass: string = isMobile ? ' Mobile' : '';
        let position: string = config.Position;
        let title: string = config.Title ? `<h2>${config.Title}</h2>` : '';
        let message: string = `<p>${config.Message}</p>`;
        let buttonClass: string = config.ButtonClass ? config.ButtonClass : 'CookieButton';
        let buttonText: string = config.Button ? config.ButtonText : '';
        let isDefault: string = config.Button ? '' : 'CloseDefault';
        let button: string = `<button id="CookieClose" class="${isDefault} ${buttonClass}" aria-label="Close">${buttonText}</button>`;
        let cookieRole: string = `dialog`;
        let cookieAria: string = `Cookie Message`;

        return `<div id="CookieMessage" class="${position}${mobileClass}" role="${cookieRole}" aria-label="${cookieAria}">
                <div class="CookieColContainer">
                <div class="CookieCol CookieCol1">${title}${message}</div>
                <div class="CookieCol CookieCol2">${button}</div>
                </div></div>`;
    }
}
