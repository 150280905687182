import "./css/index.scss";

import $ from 'jquery';
import Cookies from 'js-cookie';
import {Configuration} from "./Models/Configuration/Configuration";
import {CookieMessageService} from "./Services/CookieMessageService";
import {ICookieMessageService} from "./Interfaces/ICookieMessageService";

function getWindowSize(Breakpoint: number) {
    let windowSize = Math.max((window as any).innerWidth, window.innerWidth);
    return windowSize < Breakpoint;
}

export class Application {
    private readonly Configuration: Configuration;
    private CookieMessageService: ICookieMessageService;

    constructor(config: Configuration) {
        this.Configuration = new Configuration();
        Object.assign(this.Configuration, config);
        this.CookieMessageService = new CookieMessageService(this.Configuration);
    }

    public async RenderCookieMessageContent() {
        let cookie: string = Cookies.get('cookie-notify');
        if(cookie !== 'true') { // if 'true' cookie has been set.
            let config = this.Configuration;
            let cookieMessageTarget: HTMLElement = document.getElementsByTagName('body')[0];
            let isMobile: boolean = getWindowSize(config.Breakpoint);
            $(this.CookieMessageService.GenerateCookieMessage(isMobile)).prependTo(cookieMessageTarget);
            await Application.HandleResize(config);

            // Handle close.
            let closeButton: JQuery<HTMLElement> = $('#CookieClose');
            $(closeButton).on('click', function() {
                Application.HandleAccept(config);
            });
            $(closeButton).on('focus', function() {
                $(document).on('keyup', function(event) {
                    if (event.keyCode == 27) { // Escape.
                        Application.HandleAccept(config);
                    }
                });
            });
        }
    }

    private static async HandleAccept(config: Configuration) {
        let duration: number = config.Duration;
        $('#CookieMessage').remove();
        Cookies.set('cookie-notify', 'true', { expires: duration, path: '/' });
    }

    private static async HandleResize(config: Configuration) {
        let timer = undefined;
        window.onresize = () => {
            timer ? clearTimeout(timer) : '';
            timer = setTimeout(() => {
                let el: JQuery<HTMLElement> = $('#CookieMessage');
                getWindowSize(config.Breakpoint) ? $(el).addClass('Mobile') : $(el).removeClass('Mobile');
            }, 200);
        }
    }
}
